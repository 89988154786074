.submit-btn-document[data-v-9f94c824]{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    border: 0;
    margin-top: 10px;
    padding: 6px 10px;
    background-color: var(--main-clr);
    color: var(--text-clr);
    cursor: pointer;
}
.submit-btn-document[data-v-9f94c824]:disabled{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  opacity: 0.5;
  cursor: inherit !important;
  border-radius: 8px;
  border: 0;
  margin-top: 10px;
  padding: 6px 10px;
  background-color: var(--main-clr);
  color: var(--text-clr);
  cursor: pointer;
}
.btns-container[data-v-9f94c824]{
  display: flex;
  gap: 20px;
}