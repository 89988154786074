img[data-v-1ce2225b] {
  width: 18px;
  height: 18px;
}
.flex-bots[data-v-1ce2225b] {
  /* align-items: center; */
  display: flex;
  max-width: 1000px;
  width: 50%;
  gap: 5px;
  flex-direction: column;
  /* align-items: center; */
  flex-wrap: wrap;
  /* width: 100%; */
}
.bot[data-v-1ce2225b] {
  background-color: var(--lighter-grey-clr);
  border-radius: 10px;
  padding: 0.5em 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.icon-name-container[data-v-1ce2225b] {
  display: flex;
  gap: 8px;
  font-family: Assistant;

  align-items: center;
  font-size: 18px;
}
button[data-v-1ce2225b] {
  background-color: transparent;
  outline: none;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-btns-actions[data-v-1ce2225b] {
  display: flex;
}
.flex-btns-actions button[data-v-1ce2225b] {
  cursor: pointer;
}
