.modal-full-screen[data-v-078e83dc] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(44, 44, 44, 0.46);
  z-index: 999;
}
.header[data-v-078e83dc] {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 40px;
  color: #260747;
  color: var(--dark-purple, #260747);
  gap: 10px;
  justify-content: center;
}
.header-buttons[data-v-078e83dc] {
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  gap: 10px;
}
.download-btn[data-v-078e83dc] {
  margin-top: "7px";
  color: #260747;
  color: var(--dark-purple, #260747);
}
.sub-header[data-v-078e83dc] {
  font-size: "20px";
}
.modal-content sub-header[data-v-078e83dc] {
  color: #260747;
  color: var(--dark-purple, #260747);
  font-weight: 600px;
  font-size: "20px";
  font-style: normal;
}
.modal-container[data-v-078e83dc] {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: flex-start;
  align-items: center;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background-color: white;
  border-radius: 16px;
  border: solid 1px #979797;
  z-index: 200;
  width: calc(100vw - 430px);
  /* max-width: 70%; */
  /* max-width: 1600px; */
  max-height: calc(100vh - 150px);
  height: auto;
  z-index: 999;
  overflow-y: auto;
}
.modal-close-btn[data-v-078e83dc] {
  top: 30px;
  right: 30px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 30px;
  position: absolute;
}
.modal-content[data-v-078e83dc] {
  display: flex;
  flex-direction: row;
  gap: 80px;
  max-width: 1500px;
}
.document-details[data-v-078e83dc] {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  width: 468px;
}
.details-info-headers[data-v-078e83dc] {
  text-align: right;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-weight: 400;
}
.details-info-icons[data-v-078e83dc] {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  text-align: right;
}
.details-part[data-v-078e83dc] {
  display: flex;
  flex-direction: column;
  justify-content: 'flex-end';
  gap: 40px;
}
.details-part svg[data-v-078e83dc] {
  cursor: auto;
}
.details-sub-headers[data-v-078e83dc] {
  text-align: right;
  width: 150px;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  gap: 5px;
}
.document-prompts[data-v-078e83dc] {
  display: flex;
  flex-direction: column;
  /* max-width: 820px;
  width: 820px; */
  gap: 10px;
}
.document-prompts-titles[data-v-078e83dc] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  text-align: right;
  gap: 10px
}
.details-sub-headers span[data-v-078e83dc] {
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}
.document-prompts span[data-v-078e83dc] {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: row-reverse;
}
.crm-button[data-v-078e83dc] {
  border: solid 1px #9631FF;
  border: solid 1px var(--cellcom-purple, #9631FF);
  border-radius: 6px;
  cursor: pointer;
  width: 117px;
  height: 22.222px;
  display: flex;
  justify-content: center;
}



/* .ag-theme-alpine .ag-header-cell .ag-icon-asc {
  fill: #9631FF;
}

.ag-theme-alpine .ag-header-cell .ag-icon-desc {
  fill: #9631FF;
}

.ag-theme-alpine .ag-header-cell .ag-header-icon {
  fill: #9631FF;
  color: #9631FF;
}

.ag-icon-asc {
  color: #9631FF
}

.ag-icon-desc {
  color: #9631FF
} */