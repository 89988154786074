img[data-v-4978905b]{
    width:18px;
    height: 18px;
}
.flex-files[data-v-4978905b]{
    /* align-items: center; */
    display: flex;
    max-width: 1000px;
    width: 50%;
    gap:5px;
    flex-direction: column;
    /* align-items: center; */
    flex-wrap: wrap;
    /* width: 100%; */
}
.file[data-v-4978905b]{
        background-color: var(--lighter-grey-clr);
        border-radius: 10px;
        padding: 0.5em 1em;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
}
.icon-name-container[data-v-4978905b]{
    display: flex;
    gap: 8px;
    font-family: Assistant;

    align-items: center;
    font-size: 18px;
}
button[data-v-4978905b]{
    background-color: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-btns-actions[data-v-4978905b]{
    display: flex;
}
.flex-btns-actions button[data-v-4978905b]{
    cursor: pointer;
}